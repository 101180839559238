<template>
  <div>
    <h3>基本信息</h3>
    <div class="title1">
      <div>设备编号：5614564</div>
      <div>设备名称：某某显示屏</div>
      <div>设备厂商：某某厂商</div>
      <div>创建时间：2021-12-15</div>
    </div>
    <h3 style="margin-top:20px">绑定信息记录</h3>
    <el-table
      :data="tableData"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      border
      style="width: 80%;margin-top:20px"
    >
      <el-table-column prop="date" label="市场"> </el-table-column>
      <el-table-column prop="name" label="摊位"> </el-table-column>
      <el-table-column prop="address" label="商户"> </el-table-column>
      <el-table-column prop="address" label="绑定时间"> </el-table-column>
    </el-table>
    <h3 style="margin-top:20px">维护记录</h3>
    <el-table
      :data="tableData"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      border
      style="width: 80%;margin-top:20px"
    >
      <el-table-column prop="date" label="维修情况"> </el-table-column>
      <el-table-column prop="name" label="维修类型"> </el-table-column>
      <el-table-column prop="address" label="维护人"> </el-table-column>
      <el-table-column prop="address" label="维修时间"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'particulars',
  data () {
    return {
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.title1 {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 20px;
}
</style>
